import * as React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"

import { projects } from "../../components/data/projects"

import ProjectHeader from "../../components/project-header/project-header"
import ReadNext from "../../components/read-next/read-next"
import { RoughNotation } from "react-rough-notation"

const theme = projects['mastercard'].themeColor;

const ProjectPage = () => (
  <>
    <Seo title="Mastercard" />
    <ProjectHeader projectObj={projects['mastercard']} />
    <section className={`fix-top fix-bottom`}>
      <div className={`content--md-center`}>
        <h2>Overview</h2>
        <p className={`primary lh`}>In the summer of 2018, I worked with Mastercard Advisors as a summer intern where I was a part of the analytics team. I was given a solo project of using Mastercard's transaction level data of 9 million customers to predict the category of a customer's next purchase. The inspiration behind this problem was to draw insights about a cardholder's motivation, learn about high-spend merchant categories that the customer frequents while giving weight to customers' priorities.</p>
        <p className={`primary lh`}>This was an intriguing and challenging problem because of various hidden constraints like model complexity, computation time, and the random purchase behaviour of customers. I can't share the exact details about the model and its results. However, below I summarise my experience working on this problem.</p>
      </div>
    </section>
    <section className={`fix-top fix-bottom`}>
      <div className={`content--md-center`}>
        <h6 style={{ color: theme}}>Project: Machine Learning</h6>
        <h2>Purchase Prediction</h2>
        <p className={`primary lh`}>To predict customer behaviour, I chose to use deep learning to mimic human behaviour training on thousands of data samples of previous transactions. I used a RNN model (using Gated Recurrent Unit cells) to make sequential predictions. This model was evaluated by considering three categories of customers.</p>
        <ul>
          <li>Biased customers: Frequenting purchases in mostly one industry</li>
          <li>Patterned customers: Frequenting purchases in 3-10 industries</li>
          <li>Random customers: Frequenting purchases in multiple industries ( &gt; 10 )</li>
        </ul>
        <p className={`primary lh`}>The prediction results in the three cases were overlapped with untrained real data points and this evaluation helped improve the model to produce better results for the individual cases.</p>
          <div className={`project-image`}>
            <StaticImage
              src={'../../images/projects/mastercard/Predictions.png'}
              width={1200}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Reading Screen"
              loading={`eager`}
            />
            <p className={`caption`}>Comparing Predictions</p>
          </div>
        <p className={`primary lh`}>Taking the predictions a step further, after predicting the category of purchase with an appreciable accuracy, I was given the task to predict a time estimate for the next purchase. This was done by translating dates to a time sequence and training it over a standard neural network model.</p>
          <div className={`project-image`}>
            <StaticImage
              src={'../../images/projects/mastercard/Dates.png'}
              width={1200}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Reading Screen"
              loading={`eager`}
            />
            <p className={`caption`}>Model comparison for time predictions</p>
          </div>
        <p className={`primary lh`}>The tasks were challenging due to the complete randomness of human behaviour, however that's what ML techniques are for — to find patterns that aren't obvious or easily discernable by standard algorithms. Despite the randomness, the models performed better than expected and the margin of error was within range to still have some relevance.</p>
      </div>
    </section>
    <section className={`fix-top fix-bottom`}>
      <div className={`content--md-center`}>
        <h6 style={{ color: theme}}>Project: UX Design & Frontend Development</h6>
        <h2>Bulletin Operations Platform</h2>
        <p className={`primary lh`}>I also contributed to the UX design and development of an internal bulletin operations web platform being built to alleviate various pain points for the bulletin's vertical and management.</p>
    
        <p className={`primary lh`}>We worked towards defining the use cases of this platform for different stakeholders using user personnas, created user journey maps to make the features easily accessible for each personna, proposed wireframes, and developed and deployed the entire platform within the span of the internship.</p>
      </div>
    </section>
    <section className={`fix-top fix-bottom`}>
      <div className={`content--md-center`}>
        <h6 style={{ color: theme}}>Extra Credits</h6>
        <h2>Merchant Solutions AI Platform</h2>
        <p className={`primary lh`}>Apart from my work, I took part in various global competitions and challenges organised for the employees. One of them led me to develop a merchant solutions platform to run AI powered campaigns. I built the platform using Angular 6.</p>
          <div className={`project-image`}>
            <StaticImage
              src={'../../images/projects/mastercard/MerchantAI1.png'}
              width={1200}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Reading Screen"
              loading={`eager`}
            />
          </div>
          <div className={`project-image`}>
            <StaticImage
              src={'../../images/projects/mastercard/MerchantAI2.png'}
              width={1200}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Reading Screen"
              loading={`eager`}
            />
          </div>
          <div className={`project-image`}>
            <StaticImage
              src={'../../images/projects/mastercard/MerchantAI3.png'}
              width={1200}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Reading Screen"
              loading={`eager`}
            />
          </div>
        <p className={`primary lh`}>I was also lucky enough to file and receive two patents through Mastercard during this internship.</p>
      </div>
    </section>
    <ReadNext projectObj={projects['poeticAI']} />
  </>
)

export default ProjectPage